import React from 'react';
import { Items } from '../../pages/resources';

export const Resources = () => {
  return (
    <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-5xl font-bold text-gray-900 font-pj">Resources</h2>
          <h2 className="text-lg font-medium text-gray-700 font-pj">
             Resources to help you build more efficiently
          </h2>
        </div>

        <Items />
      </div>
    </section>
  );
};
