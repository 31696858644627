import React from "react";

import heroImage2 from "../images/aintrowindframe.png";
import { StyledWrapper } from "./BlackFridayCard.style";
import image from "../images/blackfriday.png";

export const BlackFridayCard = () => {
  return (
    <a href="/sales" className="card card-body w-100 border-0 rounded shadow text-center" style={{ marginBottom: "8rem", background: "#e9ecef"}}>
      <StyledWrapper>
        <div className="box">
          <h3 className="mt-4 mb-3 header"> Sale! 🎁🎁</h3>
          <h5 className="body">
           Get Amazing deals for a limited time
          </h5>
          <img className="my-5 mx-auto w-75" alt="heroImage" style={{ height: "auto" }} src={image}/>
          <div className="button">
            <a href="/sales"><button>Enter!</button></a>
          </div>
        </div>
      </StyledWrapper>
    </a>
  );
};
