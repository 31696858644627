import styled from "styled-components";

export const StyledWrapper = styled.section`
  color: black;
  display: flex;
  justify-content: center;
  margin-bottom: 50px !important;
  .box {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 65%;
    margin: 0 auto;

    .header {
      font-weight: 600;
    }
    .body {
      font-size: 18px;
      margin: 20px 0;
    }
    @media (max-width: 768px) {
      .header {
        font-size: 25px;
      }
      .body {
        font-size: 18px;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      button {
        border: none;
        color: #3329e0;
        font-weight: 700;
        padding: 12px 35px;
        background: #ccc9ff;
        border-radius: 16px;
        cursor: pointer;
        box-shadow: 0px 5px 5px rgba(75, 93, 104, 0.1);
      }
    }
  }
  @media (max-width: 768px) {
    .box {
      width: 90%;
    }
  }
  @media (min-width: 768.99px) and (max-width: 991px) {
    .box {
      width: 70%;
    }
  }
  @media (min-width: 991.99px) and(max-width: 1440px) {
    .box {
      width: 60%;
    }
  }
   @media (min-width: 1440px) {
    .box {
      width: 60%;
    }
  }
`;
