import React, {useEffect, useState} from 'react';
import firebase from 'gatsby-plugin-firebase';
import 'firebase/firestore';
import { HomePage } from '../containers/HomePage/HomePage';

const Home = () => {

  const [res, setRes] = useState([]);
  const docRef = firebase.firestore().collection('resources');
  
  // const docRefUsers = firebase.firestore().collection('users');

  // function arrayToCSV(objArray) {
  // const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  // let str = `${Object.keys(array[0])?.map(value => `"${value}"`)?.join(",")}` + '\r\n';

  //   return array.reduce((str, next) => {
  //     str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
  //     return str;
  //   }, str);
  // }
  
  
  useEffect(() => { 
    //get ressources
    docRef
      .get()
      .then((collection) => {
        const docres = [];

        collection.forEach(doc => {

          docres.push(doc.data());

        });
        setRes(docres);
      });
    
    // docRefUsers.where("createdAt", ">=", new Date("2024,08,09"))
    //   .where("createdAt", "<", new Date("2024,09,17")).get().then((collection) => {
    //   const docresusers = [];

    //   console.log(collection)

    //   collection.forEach(doc2 => {

    //     console.log(doc2)

    //     let doc = doc2.data()
    //     console.log(doc)

    //     if (doc.name) {

    //       let firstNameArr = doc.name.split(" ");

    //       let firstName = firstNameArr[0];

    //       const obj = {
    //         email: doc.email,
    //         name: doc.name,
    //         firstname: firstName,
    //       }

    //       docresusers.push(obj);
    //     }
      

    //   });

    //   console.log(arrayToCSV(docresusers));

      
    // });

    // docRefUsers.get().then((collection) => {
    //   const docresusers = [];

    //   console.log(collection)

    //   collection.forEach(doc2 => {

    //     console.log(doc2.id)

    //     let doc = doc2.data()
    //     console.log(doc)

    //     if (doc.isActive) {

    //       docRefUsers.doc(doc2.id).update({
    //         currentPlan: "pro"
    //       })

    //       // let firstNameArr = doc.name.split(" ");

    //       // let firstName = firstNameArr[0];

    //       // const obj = {
    //       //   email: doc.email,
    //       //   name: doc.name,
    //       //   firstname: firstName,
    //       // }

    //       // docresusers.push(obj);
    //     } 
    //     else {
    //       docRefUsers.doc(doc2.id).update({
    //         currentPlan: "free"
    //       })
    //     }


    //   });


    // });
    "email", "name", "firstname"
    "alexandrepaiva308@gmail.com", "Alexandre Paiva", "Alexandre"
    "contrerasdannniel@gmail.com", "Carlos Daniel Contreras Hernandez", "Carlos"
    "erazocesar879@gmail.com", "MrDino", "MrDino"
    "bleach4erver@gmail.com", "Studio Bleach", "Studio"
    "lizy0329@163.com", "zy li (lizy)", "zy"
    "info@webriq.com", "WebriQ", "WebriQ"
    "bundac2121418@mkt.ceu.edu.ph", "Carlos Miguel Bundac", "Carlos"
    "anh328947@gmail.com", "Nguyễn Hoàng Anh", "Nguyễn"
    "john.vu.itm@gmail.com", "John IT", "John"
    "komall.ayub.117@gmail.com", "Komal Ayub", "Komal"
    "alex.palaghioi@gmail.com", "Iustin-Alexandru Palaghioi", "Iustin-Alexandru"
    "jacobdale98@aol.com", "Camden", "Camden"
    "davi42@hotmail.es", "david", "david"
    "appwebbase@gmail.com", "App Webbase", "App"
    "toainn03@gmail.com", "Toại Nguyễn", "Toại"
    "sergey.medvedev@gmail.com", "Sergey Medvedev", "Sergey"
    "sonicaxy@gmail.com", "Enes S", "Enes"
    "dee.bogdanov@gmail.com", "Dmytro Bogdanov", "Dmytro"
    "abdullahraad955@gmail.com", "Abdullah Raad", "Abdullah"
    "flourinee@gmail.com", "Phi Long Nguyễn", "Phi"
    "ioanclickmihalca@gmail.com", "Ioan Click", "Ioan"
    "teerthendras@gmail.com", "Teerthendra Singh", "Teerthendra"
    "praneshgara@gmail.com", "Pranesh V", "Pranesh"
    "ngasamah@gmail.com", "Henoc N'gasama", "Henoc"
    "harshkumar3166@gmail.com", "Harsh", "Harsh"
    "bonnandalexandre@gmail.com", "Bonnand Alexandre", "Bonnand"
    "fastidous@gmail.com", "Boma Princewill", "Boma"
    "alcazarfabricio2@gmail.com", "FABRICIO ALONSO ALCAZAR GOMEZ (FabriGamerYT19)", "FABRICIO"
    "hkma907@gmail.com", "Husam Aljamal", "Husam"
    "i@starrn.com", "Starrn", "Starrn"
    "abdallah.khalil.nada@gmail.com", "Abdallah khalil", "Abdallah"
    "hey@bro.pm", "Mantas Ramonas", "Mantas"
    "valentaizarstone@gmail.com", "Valentaizar Hitsukaya", "Valentaizar"
    "uncle.milad@gmail.com", "Milad faraji", "Milad"
    "viper7854@gmail.com", "Sajith Rathnayaka", "Sajith"
    "filaso3954@luvnish.com", "ebc ebc", "ebc"
    "redfield005@gmail.com", "Dmitry Kamiannoj", "Dmitry"
    "visethchea1212@gmail.com", "Chea Oengvsieth", "Chea"
    "esteban.felipe@8base.com", "Esteban Felipe", "Esteban"
    "kolenko.evg@gmail.com", "Евгений Коленко", "Евгений"
    "alina.ipatova2098@gmail.com", "Аліна Овод", "Аліна"
    "timo.dev.gruber@gmail.com", "Timo Gruber", "Timo"
    "fawzinofal7@gmail.com", "Fawzi Nofal", "Fawzi"
    "aqeelomar29@gmail.com", "baraa aqeel", "baraa"
    "ross.illingworth@gmail.com", "ross illingworth", "ross"
    "s.rubio131@gmail.com", "Sergio Rubio", "Sergio"
    "mahdi.eftekhaari@gmail.com", "Mahdi Eftekhari", "Mahdi"
    "mail@dmomot.com", "Dmytro Momot", "Dmytro"
    "m.zavarsky@gmail.com", "matus zavarsky", "matus"
    "lautaroceballos2013@gmail.com", "Code_and_beers", "Code_and_beers"
    "roberto.carpinteyro96@gmail.com", "Roberto Carpinteyro", "Roberto"
    "anasbrototype@gmail.com", "anas k", "anas"
    "lawrencm@gmail.com", "Matthew Lawrence", "Matthew"
    "red.techno.questions@gmail.com", "red tech", "red"
    "ahmerkhan357@gmail.com", "Ahmar", "Ahmar"
    "jardim.sergio@gmail.com", "Sérgio Jardim", "Sérgio"
    "thaihanguyen98@gmail.com", "Thai Ha Nguyen", "Thai"
    "x495153032d@gmail.com", "D Xu (十号工作室)", "D"
    "nicekungx@gmail.com", "Nicezki", "Nicezki"
    "hh4morsy@gmail.com", "HAZEM MORSY", "HAZEM"
    "vanegasalejandro005@gmail.com", "Alejandro Vanegas", "Alejandro"
    "sethjm@gmail.com", "Seth Martin", "Seth"
    "linaluthsara1@gmail.com", "Linal Uthsara", "Linal"
    "tojkuv@outlook.com", "Livan", "Livan"
    "yanier@graydient.ai", "Yanier Gonzalez", "Yanier"
    "jiawei.4127@gmail.com", "Jizawy", "Jizawy"
    "mosibitan@163.com", "mosi", "mosi"
    "meinfrp@gmail.com", "SCIS LOST", "SCIS"
    "jforeverything2007@gmail.com", "Mr. Lucks", "Mr."
    "dnyanu.waghunde@gmail.com", "Dnyandeo Waghunde", "Dnyandeo"
    "theledraa@gmail.com", "Ledraa", "Ledraa"
    "nqkhanh2003@gmail.com", "Nguyễn Quốc Khánh", "Nguyễn"
    "harinlenya1488@gmail.com", "Leonid Kharin", "Leonid"
    "rlopezaldana@gmail.com", "Raul Lopez", "Raul"
    "sdezwayam@gmail.com", "Zwayam Software Development Engineer", "Zwayam"
    "gallegos_nada@hotmail.com", "José Manuel", "José"
    "danny@keepingcurrentmatters.com", "Danny McPeak", "Danny"
    "lfoside@gmail.com", "Никита Шёлкин", "Никита"
    "thananwat.thanabowornsawat@gmail.com", "thananwat thanabowornsawat", "thananwat"
    "16lah6@queensu.ca", "Liam Hough", "Liam"
    "rakesh@v3.cash", "Rakesh Kumar", "Rakesh"
    "stt.group.backup@gmail.com", "STT-GROUP BACKUP", "STT-GROUP"
    "sjym90@gmail.com", "Gideon Stele", "Gideon"
    "mtextual@gmail.com", "Meta Textual", "Meta"
    "matheyraud@gmail.com", "Mathieu Heyraud", "Mathieu"
    "vbcmgt@gmail.com", "VBC Mgt", "VBC"
    "emin@jasarevic.com", "Emin Jasarevic", "Emin"
    "jazinthecity@gmail.com", "jaz inthecity", "jaz"
    "dziedziela@gmail.com", "Mac Dziedziela", "Mac"
    "robin.sannya@gmail.com", "Robin Sannya", "Robin"
    "aebm969@gmail.com", "elias", "elias"
    "contact@thoughtgeek.com", "Surya", "Surya"
    "luisjuarez21@duck.com", "juarez", "juarez"
    "nash8@live.com", "Nash Gadre", "Nash"
    "trunght@liftsoft.vn", "Trung HT", "Trung"
    "rntmixing@gmail.com", "Rudra Sahoo", "Rudra"
    "alamsyah.as585@gmail.com", "Alam Syah", "Alam"
    "nazarowa48951@gmail.com", "Alina", "Alina"
    "meetgo.go.go@gmail.com", "Meet Google", "Meet"
    "oengviseth.chea@gmail.com", "KingTwentyDev", "KingTwentyDev"
    "ionut.rusu@hsdsuk.com", "Rusu", "Rusu"
    "vcappuccio@gmail.com", "vcapp", "vcapp"
    "priyankagoel808@gmail.com", "priyanka goel", "priyanka"
    "besheinuybanan25@gmail.com", "shrex", "shrex"
    "abdokhater278@gmail.com", "Abdo Khater", "Abdo"
    "miqochaloyan@gmail.com", "Miqo Chaloyan", "Miqo"
    "sayelen299@polatrix.com", "Pintu", "Pintu"
    "mateusangelo@gmail.com", "Mateus Angelo Paula", "Mateus"
    "sthompson@aceventurefunds.com", "Steven Thompson", "Steven"
    "felipecpc@gmail.com", "Felipe Castro", "Felipe"
    "noory123.azghar@gmail.com", "Azghar Din noory", "Azghar"
    "macobilo97@gmail.com", "TiTax", "TiTax"
    "bakos88@gmail.com", "Alexander Leirvåg", "Alexander"
    "mohamedhassanwork2887@gmail.com", "Mohamed Hassan", "Mohamed"
    "inamras@raspberry-jam.net", "Inamras Luzd", "Inamras"
    "sazonovk.ua@gmail.com", "Кирило Сазонов", "Кирило"
    "darshan.kharva11@gmail.com", "darshan kharva", "darshan"
    "marcus@junstrom.se", "Marcus Junström", "Marcus"
    "contact@josiahboman.com", "Josiah Boman", "Josiah"
    "vcapp@ipsec.ro", "v capp", "v"
    "amcheste@gmail.com", "Alan Chester", "Alan"
    "ignat.pocevski@gmail.com", "Ignat Pocevski", "Ignat"
    "waynenilsen@gmail.com", "Wayne Nilsen", "Wayne"
    "amir.hossin.mostafaei1388@gmail.com", "amir.hossin 1388", "amir.hossin"
    "dmytro_artemiev@ecommajesty.com", "Dmytro Artemiev", "Dmytro"
    "kist.aaa@gmail.com", "F. Kist", "F."
    "fritsch21@gmail.com", "Haroldo Fritsch", "Haroldo"
    "pkrahul1479@gmail.com", "Rahul Pk", "Rahul"
    "ahmadhaz75@gmail.com", "Ahmad Alhazmi", "Ahmad"
    "kmcmahon@phoenix-group.com", "Kenneth McMahon", "Kenneth"
    "junioradelfocalderonnolazco@gmail.com", "Junior Adelfo Calderon Nolazco", "Junior"
    "emredersinsahip@gmail.com", "Emredersin Sahip", "Emredersin"
    "danny.bud@gmail.com", "Danny Bud", "Danny"
    "toomnuspt@gmail.com", "Peranut Toomnus", "Peranut"
    "louis.chabanon@gmail.com", "Louis | Lordva", "Louis"
    "dxtlive@gmail.com", "Shagbaor Agber", "Shagbaor"
    "thembahank@gmail.com", "themba (m)", "themba"
    "ashishkwins@gmail.com", "Ashish Kumar", "Ashish"
    "sopitools@gmail.com", "Gas Man", "Gas"
    "james.42.cb@gmail.com", "James Moore", "James"
    "jhsalman53@gmail.com", "Jh Salman", "Jh"
    "jozef.podhajecky@gmail.com", "Jozef Podhájecký", "Jozef"
    "zalabinc@gmail.com", "Zalab Inc", "Zalab"
    "gabriel@ondecor.com", "Gabriel Batista", "Gabriel"
    "gavinhoward100@gmail.com", "Gavin Howard", "Gavin"
    "niccolo047@gmail.com", "Niccolò Diana", "Niccolò"
    "santiagoagdc42@gmail.com", "Santiago Aguirre", "Santiago"
    "jlwebster@gmail.com", "John Webster", "John"
    "ntrantrinh@gmail.com", "Nathan Tran Trinh", "Nathan"
    "danny.grijal99@gmail.com", "Danny Enrique Grijalba Gutiérrez", "Danny"
    "mark.koestenberger@pascalnet.at", "Mark Koestenberger", "Mark"
    "maxalpashkin@gmail.com", "Max Alpashkin", "Max"
    "rbekim50@gmail.com", "Bekim Rexhepi", "Bekim"
    "akun7tumbal6@gmail.com", "akun tumbal", "akun"
    "justfirstpost@gmail.com", "German K", "German"
    "pedramjalaeyan@gmail.com", "Pedram", "Pedram"
    "hannes-stadthagen@web.de", "Hannes Nowak", "Hannes"
    "grahamtg@gmail.com", "Graham Giangiulio", "Graham"
    "asdfyolo1337@gmail.com", "Peter Lustig", "Peter"
    "mrdjeb245@gmail.com", "MrDjeb", "MrDjeb"
    "poolkasemkanok007@gmail.com", "Poolkasem Kanok", "Poolkasem"
    "terynobparat@gmail.com", "nobparat bunchalor", "nobparat"
    "xurshedziyovaddinov1@gmail.com", "Khurshed Ziyovaddinov (cosmoboy)", "Khurshed"
    "iakovossiakovos@gmail.com", "iakovos zouu", "iakovos"
    "mail@lenildosantos.pt", "Lenildo Santos", "Lenildo"
    "hafidmosquera@gmail.com", "Hafid Mosquera", "Hafid"
    "alejandro@colmenadigital.net", "Alejandro Pérez-canto", "Alejandro"
    "alexandref.silva0711@gmail.com", "Alexandre Ferreira", "Alexandre"
    "sumbh786@gmail.com", "Sumeera Younus", "Sumeera"
    "cparkone@naver.com", "Park Chul", "Park"
    "nathnaelwonisha@gmail.com", "Nathnael Wondisha", "Nathnael"
    "janhavna@gmail.com", "Jan-petter Havna", "Jan-petter"
    "ali.hamza3108@gmail.com", "Ali Hamza", "Ali"
    "saqibmujeeb085@gmail.com", "Saqib mujeeb", "Saqib"
    "jorge.liralopez11@gmail.com", "Jorge Adrian Lira Lopez", "Jorge"
    "croller2019@gmail.com", "Cont Roller", "Cont"
    "shabihaema916@gmail.com", "Shabiha Ema", "Shabiha"
    "rayanhnide485@gmail.com", "Rayan Hnide", "Rayan"
    "shivaji.techisor@gmail.com", "Shivaji Zirpe", "Shivaji"
    "brnd@gmail.com", "berna", "berna"
    "fondoderes@gmail.com", "jesus", "jesus"
    "thihaaung7779@gmail.com", "thiha aung", "thiha"
    "ifinancing360@ims-tec.com", "iFinancing360", "iFinancing360"
    "fatememovassagh@gmail.com", "Fateme Movassagh", "Fateme"
    "heraxos04@gmail.com", "mix mix", "mix"
    "ivan@bitgenio.com", "Iván Argulo", "Iván"
    "mateus.bonet@npu.com.br", "Mateus Bonet", "Mateus"
    "oltyan.alin@gmail.com", "Alin Oltyan", "Alin"
    "heyjohfa@gmail.com", "Johanna Fagerås", "Johanna"
    "msprestosa@gmail.com", "Marc Prestosa", "Marc"
    "websokol@gmail.com", "Denis Knyazev", "Denis"
    "ronny.klein@me.com", "Ronny Klein", "Ronny"
    "mattsupichu@gmail.com", "Matthew Wong", "Matthew"
    "mellomellyy@gmail.com", "Melly O", "Melly"
    "vromansn+github@pm.me", "Nico Vromans", "Nico"
    "psteeleapu@gmail.com", "psteeleapu", "psteeleapu"
    "leo.miranda.nogueira.2009@gmail.com", "Leonardo Miranda", "Leonardo"
    "oneigcy@gmail.com", "ismail andaloussi", "ismail"
    "r@tixbyllc.com", "Ricardo Aponte", "Ricardo"
    "m.r.kh.d.1381@gmail.com", "محمدرضا خدامی", "محمدرضا"
    "beecreative0509@gmail.com", "Keval Vora", "Keval"
    "admin@gmail.com", "admin", "admin"
    "samrat.reddot@gmail.com", "admin", "admin"
    "dan@techba.se", "Dan Larsen", "Dan"
    "kern.mark67@gmail.com", "Márk Kern", "Márk"
    "victorscarani@gmail.com", "Victor Scarani", "Victor"
    "alphalynxjet@gmail.com", "Arnold", "Arnold"
    "mxnjani@gmail.com", "Munjani", "Munjani"
    "gameking43221@gmail.com", "Reader", "Reader"
    "danielberas.s@hotmail.com", "Daniel Beras Sánchez ", "Daniel"
    "danielberas18@gmail.com", "Daniel Beras Sánchez ", "Daniel"
    "mtanbic@gmail.com", "Mark Tanbic", "Mark"
    "ahmedchaabni@gmail.com", "Chaabni Ahmed", "Chaabni"
    "connell.james@gmail.com", "James Connell", "James"
    "pelie.romer@gmail.com", "ROMER Pierre-Elie", "ROMER"
    "arafat.esc@gmail.com", "Arafat Islam Khairul", "Arafat"
    "khien23092003@gmail.com", "Nguyen Gia Khien", "Nguyen"
    "ritikdave9@gmail.com", "ritikdave", "ritikdave"
    "karasan.6491@gmail.com", "Hasan SARI", "Hasan"
    "koushiksk.ks@gmail.com", "koushik sarker", "koushik"
    "caorrico@espe.edu.ec", "Camilo", "Camilo"
    "cristianbarreirofag@gmail.com", "Cristian Barreiro", "Cristian"
    "lunadaniel@gmail.com", "Daniel Luna", "Daniel"
    "alex@galaxywebteam.com", "Alex Boeger", "Alex"
    "developer.suresh@gmail.com", "Suresh Sekar", "Suresh"
    "piccotalent185@gmail.com", "Muthamizh Selvan", "Muthamizh"
    "weijing.z@gmail.com", "Kelly Zhang", "Kelly"
    "promotionsingh13@gmail.com", "promotion singh", "promotion"
    "a17682356564@gmail.com", "景色各千秋", "景色各千秋"
    "xindz97@gmail.com", "东新", "东新"
    "singhtech216@gmail.com", "Tech Singh", "Tech"
    "cdavis@secured.ai", "Chad Davis", "Chad"
    "batuaydemir.edu@gmail.com", "Batuhan Aydemir", "Batuhan"
    "hellings.sergio11@gmail.com", "icycouselo", "icycouselo"
    "icycouselo@gmail.com", "icycouselo", "icycouselo"
    "shahrukh@phoenux.design", "Shahrukh Azhar", "Shahrukh"
    "nikita.yaroshevich@gmail.com", "Nikita", "Nikita"
    "h72x@pm.me", "Hafnium", "Hafnium"
    "jackdavis0921@gmail.com", "Jack Davis", "Jack"
    "kadu0410@gmail.com", "Carlos Eduardo Dev", "Carlos"
    "alex.pcii04@gmail.com", "Alex Ardhian", "Alex"
    "sbizarroquisuruco@gmail.com", "stiven romel bizarro quisuruco", "stiven"
    "kamonnnnn0204@gmail.com", "001 กมลรัตน์ เล้นสิ้น", "001"
    "eloircasas@gmail.com", "Eloir Casas", "Eloir"
    "fauzulrahman328@gmail.com", "Fauzul Rahman", "Fauzul"
    "revathi.picco@gmail.com", "Revathi M", "Revathi"
    "dtaurskiy@gmail.com", "Даниил Таюрский", "Даниил"
    "artur.catch@hotmail.it", "Artur Arseniev", "Artur"
    "giorgosfourkas.98@gmail.com", "fourkas giorgos", "fourkas"
    "marotr@gmail.com", "Raphaël Marot", "Raphaël"
    "kamila@crochet.pt", "Kamila Moura", "Kamila"
    "ssbannerjee@gmail.com", "Surya Banerjee", "Surya"
    "diegoocelot@gmail.com", "Diego", "Diego"
    "amarprabhu01@gmail.com", "Amar Prabhu", "Amar"
    "herve@catchthefever.com", "Herve Drompt", "Herve"
    "sulaimsaeed@GMAIL.COM", "sulaim", "sulaim"
    "rrbs80@gmail.com", "rr bs", "rr"
    "haliloncen09@gmail.com", "Halil", "Halil"
    "ddompei425@gmail.com", "Domingo Domínguez Peinado", "Domingo"
    "pechenegus530@gmail.com", "Pavel Moskalev", "Pavel"
    "brumdav@gmail.com", "David", "David"
    "burhankhawaja1991@gmail.com", "Burhan Khawaja", "Burhan"
    "tilekkhamza9702@gmail.com", "Khamza Tilek", "Khamza"
    "walfiannur@gmail.com", "alfian", "alfian"
    "2222195@slu.edu.ph", "RAIANNA GAYLE LORENZO", "RAIANNA"
    "yasserelabdielalaoui@gmail.com", "Yasser El Abdi El Alaoui", "Yasser"
    "nostramagear@gmail.com", "Saul", "Saul"
    "rama.assegaf31@gmail.com", "Rama Rahman 16-0134", "Rama"
    "rblanes31@gmail.com", "Ralph Blanes", "Ralph"
    "johnbshd321@gmail.com", "John Fert", "John"
    "andrzej.rogalski@epat.pl", "Andrzej Rogalski", "Andrzej"
    "darwin.chazze@gmail.com", "Chazze Darwin", "Chazze"
    "pwdesignhtx@gmail.com", "pwdesignhtx", "pwdesignhtx"
    "morya123@gmail.com", "moreshwar pantwalavalkar", "moreshwar"
    "msreactionrosh@gmail.com", "Rosh", "Rosh"
    "priteshnaikade2111@gmail.com", "Pritesh naikade", "Pritesh"
    "dr.thanuchit@gmail.com", "ธีระวัฒน์ ทนุชิต", "ธีระวัฒน์"
    "jordan@insightfultraits.com", "Jordan Iversen", "Jordan"
    "lpalmer081995@gmail.com", "Logan Palmer", "Logan"
    "mmwasifsaeed@gmail.com", "mian wasif", "mian"
    "1inall2050@gmail.com", "Rohit Kale", "Rohit"
    "martinperez.jaime01@gmail.com", "Jaime Martín Pérez", "Jaime"
    "clarke.noddie.io@gmail.com", "Clarke", "Clarke"
    "johnsoto90@gmail.com", "John Soto", "John"
    "kira012009750@gmail.com", "Rodrigo Rivera", "Rodrigo"
    "ismailocal@gmail.com", "İsmail Öcal", "İsmail"
    "saeedehsaboori6@gmail.com", "Saeede Saboori", "Saeede"
    "jazza.reub@gmail.com", "Reuben Jenkins", "Reuben"
    "montanafang@gmail.com", "F Montana", "F"
    "admin@licsu.com", "Juan Castrillon", "Juan"
    "raimmupanda@gmail.com", "Ikarwa", "Ikarwa"
    "st.buttgereit@googlemail.com", "Stephan Buttgereit", "Stephan"
    "mauromandala@gmail.com", "Mauro Mandalari", "Mauro"
    "kaehmsrobert@dublinusd.org", "Mr. Kaehms", "Mr."
    "milad.bourhani@gmail.com", "Milad", "Milad"
    "kluchak1818@gmail.com", "Kathryn L", "Kathryn"
    "alijunior@gmail.com", "Júnior Áli", "Júnior"
    "nasimulhasandeep@gmail.com", "Nasimul hasan Deep", "Nasimul"
    "cristofer.pro@icloud.com", "Cristofer Jimenez", "Cristofer"
    "goodgamerizqi@gmail.com", "Erzy.sh", "Erzy.sh"
    "lokman.ng@dmglab.com", "lok man ng", "lok"
    "krishanmraju@gmail.com", "krishnam raju Jampana", "krishnam"
    "prokopis123@gmail.com", "Prokopis Antoniadis", "Prokopis"
    "sachaun@gmail.com", "Sacha Ungarelli", "Sacha"
    "fabricetoyi87@gmail.com", "Fabrice Toyi", "Fabrice"
    "joseolivarest080683@gmail.com", "José Alfonso Olivares Torres", "José"
    "mahendra.cse2022@jerusalemengg.ac.in", "mahendra.cse2022 JCE", "mahendra.cse2022"
    "jasonscjcj@gmail.com", "Jason Tai", "Jason"
    "sakro_moreno92@hotmail.com", "sakrox", "sakrox"
    "tashinkan360@gmail.com", "Tashin khan", "Tashin"
    "roeeapam@gmail.com", "roee alony", "roee"
    "dan@ecommerce.events", "Dan Mullaney", "Dan"
    "dizzoid@gmail.com", "Петр К", "Петр"
    "yeakjongping0000@gmail.com", "yeak jong ping", "yeak"
    "gutivanaliefs@gmail.com", "Gutivan Alief Syahputra", "Gutivan"
    "zeliaahere@gmail.com", "ahere zelia", "ahere"
    "forkdoescrypto@gmail.com", "Fork", "Fork"
    "skybross2018@gmail.com", "Sky Bross", "Sky"
    "jamiewallisbridgend@gmail.com", "jamiewallisbridgend", "jamiewallisbridgend"
    "jsshook3@gmail.com", "Justin Shook", "Justin"
    "ycrojas18@gmail.com", "camilo", "camilo"
    "hariombaaghel@gmail.com", "hari", "hari"
    "janis.upmanis@gmail.com", "Jānis Upmanis", "Jānis"
    "aaaa@gmail.com", "aaaa", "aaaa"
    "travis.vas@gmail.com", "Travis Vasceannie", "Travis"
    "cbfranca88@gmail.com", "cbfranca88", "cbfranca88"
    "yz.clms@gmail.com", "Yannis Zorgios", "Yannis"
    "mr.kernighan@gmail.com", "Victor Chicu", "Victor"
    "varnitpatel001@gmail.com", "VARNIT PATEL", "VARNIT"
    "jackadamsjenkins@gmail.com", "Gabriel Campos", "Gabriel"
    "debasishnathdb@yahoo.com", "DEBASISH NATH", "DEBASISH"
    "d.moudilos@clmsuk.com", "Dimitris Moudilos", "Dimitris"
    "manu117999@gmail.com", "Python", "Python"
    "jamie@digital-deep.com", "Jamie Wallis", "Jamie"
    "pyerro@gmail.com", "Pyerro", "Pyerro"
    "roberto@paz.co.cr", "Roberto Rodriguez", "Roberto"
    "vaib0701@gmil.com", "vaibhav", "vaibhav"
    "saxenarashi731@gmail.com", "Rashi saxena", "Rashi"
    "bld0112@gmail.com", "ひろらいおん", "ひろらいおん"
    "mnunezve@gmail.com", "Marlon", "Marlon"
    "cesarsla08@gmail.com", "Cesar Martin", "Cesar"
    "rubenilson12@gmail.com", "Rubenilson Emerson", "Rubenilson"
    "devsinghpune@gmail.com", "Dev Singh", "Dev"
    "sampak.dev@gmail.com", "Łukasz Wojdat", "Łukasz"
    "mahdlinux@gmail.com", "mahdi kordani", "mahdi"
    "rasyiqfirmansyah16@gmail.com", "Rasyiq Firmansyah16", "Rasyiq"
    "clovery.org@gmail.com", "ArvinWei", "ArvinWei"
    "bus58156@gmail.com", "bu shi", "bu"
    "yehorbohomazov@gmail.com", "Yehor Bohomazov", "Yehor"
    "koestler.johannes1997@gmail.com", "Joh", "Joh"
    "pruebasprobyteg@gmail.com", "Gustavo", "Gustavo"
    "taskinen2005@gmail.com", "Limsesss s", "Limsesss"
    "techshad04@gmail.com", "shadab", "shadab"
    "camille.molen@gmail.com", "Camille Molen", "Camille"
    "hy@anthonykasabian.com", "Anthony Kasabian", "Anthony"
    "kipronojebet5@gmail.com", "Faith", "Faith"
    "kpkyemre@gmail.com", "Emre Kapukaya", "Emre"
    "nickyschobben@gmail.com", "Nicky Schobben", "Nicky"
    "kansagous@gmail.com", "I Lonare", "I"
    "herschelle.lonare@gmail.com", "Herschelle Lonare", "Herschelle"
    "harshal.lonare@gmail.com", "harshal lonare", "harshal"
    "itacirgabral@gmail.com", "itacir gabral", "itacir"
    "rhoque@umich.edu", "Rahul Hoque", "Rahul"
    "briancash@gmail.com", "Brian Cash", "Brian"
    "sid.kothari@addium.io", "Sid Kothari", "Sid"
    "martinezquirozadrien89@gmail.com", "enzo", "enzo"
    "alex@outmoreliving.com", "Alex Duncan", "Alex"
    "ngyzwork@gmail.com", "yinze ng", "yinze"
    "peerpeterwerner@gmail.com", "Peer Nilsson", "Peer"
    "guofeichu@gmail.com", "褚国飞", "褚国飞"
    "mdmanshadi158@gmail.com", "Mohsen Dehghan", "Mohsen"
    "aboyowaakpenyi@gmail.com", "John Akpenyi", "John"
    "imol1976@gmail.com", "Pedro Rodrigues", "Pedro"
    "systeminterludesurface@gmail.com", "Keirstyn", "Keirstyn"
    "contact.prasht@gmail.com", "Prashant", "Prashant"
    "nituchoudhary418@gmail.com", "Nitu Choudhary", "Nitu"
    "techtutorialsmrtech@gmail.com", "Connor Merk", "Connor"
    "takeshikaito1412@gmail.com", "Takeshi Seijuro", "Takeshi"
    "shizukeinn@gmail.com", "Shizu Tribore", "Shizu"
    "qiuhq72@gmail.com", "hq qiu", "hq"
    "vilinamogili423@gmail.com", "Dolan Bright", "Dolan"
    "p.vinczebertold@gmail.com", "Bertold Vincze", "Bertold"
    "benlinus1984@gmail.com", "B L", "B"
    "avishkag18@gmail.com", "Avishka Gihan", "Avishka"
    "arian.27collective@gmail.com", "Arian C27", "Arian"
    "david@carbonedge.au", "David Stephenson", "David"
    "d.tronin@copybet.com", "Denis Tronin", "Denis"
    "notsodhruv@gmail.com", "alterEGO", "alterEGO"
    "jfeinblum@gmail.com", "Josh Feinblum", "Josh"
    "femawad538@coloruz.com", "test", "test"
    "novotd00@gmail.com", "Daniel Novotný", "Daniel"
    "samareshmondal8977@gmail.com", "Samaresh", "Samaresh"
    "mdkazinaim0018@gmail.com", "naim0018", "naim0018"
    "patrickms@gmail.com", "Patrick A. Müller Suárez", "Patrick"
    "domidex01@gmail.com", "Dominique Degottex", "Dominique"
    "princedhilip21@gmail.com", "Dhilip Kumar", "Dhilip"
    "shaharyar@gmail.com", "Shaharyar ", "Shaharyar"
    "luki.pansi@gmail.com", "Lukas Pansinger", "Lukas"
    "mothusim97@gmail.com", "mothusi samuel", "mothusi"
    "cesar.goudouris@gmail.com", "César Goudouris", "César"
    "marketingsociedadperu@gmail.com", "SPBR", "SPBR"
    "david.marquez.u@gmail.com", "David Márquez", "David"
    "vinmas78@gmail.com", "vin mas", "vin"
    "martin.pagac@gmail.com", "Martin Pagac", "Martin"
    "faizananwer2011@gmail.com", "Faizan Anwer Ali Rupani", "Faizan"
    "courtney@thatscourtney.com", "That's Courtney", "That's"
    "ricardo.tun@gmail.com", "Ricardo Tun", "Ricardo"
    "creigb1@gmail.com", "Creig Boyd", "Creig"
    "sohodat@gmail.com", "Mario", "Mario"
    "evanskissi31@gmail.com", "evans kissi", "evans"
    "jaime@j-herrera.com", "Jaime Herrera", "Jaime"
    "luis.cornejo_h@mail.udp.cl", "LUIS CORNEJO HERESMANN", "LUIS"
    "truongnguenlqm@gmail.com", "Nguyễn Văn Trường", "Nguyễn"
    "hiepvs.93@gmail.com", "Hiệp Vũ Sĩ", "Hiệp"
    "sheephala0436@gmail.com", "yang", "yang"
    "oanhnt@icd-vn.com", "Oanh Nguyen Thi", "Oanh"
    "vuhaiduc@gmail.com", "duc vu hai", "duc"
    "do.quang.tu@gmail.com", "Quang Tu Do", "Quang"
    "joshuaaron623@gmail.com", "Josh Eckard", "Josh"
    "edgar.rodriguez.solano@gmail.com", "edgar rodriguez solano", "edgar"
    "sheehanpaige420@gmail.com", "Gloria Price", "Gloria"
    "phamngockhoa299@gmail.com", "Phạm Ngọc Khoa", "Phạm"
    "admin@hitonline.club", "Hit Club", "Hit"
    "admin@bainbridgechorale.net", "Michelle Jones", "Michelle"
    "tempmg0000@gmail.com", "Temp Mail", "Temp"
    "notifyafrica@gmail.com", "notify africa", "notify"
    "jordanbaindev961@gmail.com", "Jordan Bain", "Jordan"
    "misaelnv.dev@gmail.com", "Misael Noriega V", "Misael"
    "loginappscns@gmail.com", "Manuel Apps", "Manuel"
    "faketrain0@gmail.com", "Marcin Stopa", "Marcin"
    "m.mufiddin@gmail.com", "Moch Mufiddin", "Moch"
    "julia.amaral@bitboundaire.com", "Julia Amaral", "Julia"
    "phamngockhoa037@gmail.com", "Ngọc Khoa Phạm", "Ngọc"
    "dimazrizki8@gmail.com", "Dimas Rizki", "Dimas"
    "ishaang2209@gmail.com", "Ishaan Gupta", "Ishaan"
    "bakaiamine54@gmail.com", "XXpvp_gamerXX", "XXpvp_gamerXX"
    "hettab@googlemail.com", "Enzo Hettab", "Enzo"
    "labasteearl88@gmail.com", "Earl Labaste", "Earl"
    "golubin23068@gmail.com", "Андрей Романов", "Андрей"
    "nicoescribalco@gmail.com", "Nicolas Escribano", "Nicolas"
    "robert@robertbaker.dev", "Robert Baker", "Robert"
    "lakharam8302@gmail.com", "Lakha Ram", "Lakha"
    "choudharyyogesh517@gmail.com", "yogesh kumar", "yogesh"
    "mleczutokozak@gmail.com", "Bartosz", "Bartosz"
    "emrezepus@gmail.com", "Enes Aydın", "Enes"
    "elameerahmed7@gmail.com", "ahmed sayed", "ahmed"
    "sakuinjet@gmail.com", "minana's world", "minana's"
    "khjem12b2@gmail.com", "khjem le ba", "khjem"
    "zrcoder101@gmail.com", "UnderCode", "UnderCode"
    "mvf73438@nowni.com", "mvf73438@nowni.com", "mvf73438@nowni.com"
    "isin@pixerylabs.com", "Isin Fidan", "Isin"
    "tunui2553@gmail.com", "Arthit Supakitworakul", "Arthit"

  }, []);

  return <HomePage res={res} />;
};

export default Home;
