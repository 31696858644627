import React from 'react';
import { Link } from 'gatsby';
import heroImage2 from '../images/aintrowindframe.png';

export const CardSection = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-gray-900 font-pj">Windframe is Here! 🚀🚀🚀</h2>
          <p className="max-w-md mx-auto mt-4 text-base leading-6 text-gray-600 font-pj">
            A Drag and Drop visual builder and editor for Tailwind CSS
          </p>
        </div>

        <div className="max-w-4xl p-8 mx-auto mt-10 overflow-hidden bg-gray-100 rounded-3xl">
          <img src={heroImage2} className="rounded w-full" alt="WINDFRAME" />
        </div>

        <div className="flex-shrink-0 sm:flex sm:items-center sm:justify-center sm:space-x-5">
          <div className="relative inline-flex w-full mt-5 group sm:mt-0 sm:w-auto">
            <div
              className="absolute duration-1000 rotate-180 transitiona-all opacity-70 -inset-px rounded-xl blur-lg filter group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200   
              bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"
            ></div>

            <Link
              to="/windframe"
              title=""
              className="
                    relative
                    inline-flex
                    items-center
                    justify-center
                    w-full
                    px-8
                    py-3
                    text-base
                    font-bold
                    text-white
                    transition-all
                    duration-200
                    bg-gray-900
                    border-2 border-transparent
                    sm:w-auto
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                    font-pj
                    hover:bg-opacity-90
                    rounded-xl
                "
            >
              Build a website
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
