import React from 'react';
import Helmet from 'react-helmet';
import { HeroSection } from '../../components/HeroSection/HeroSection';
import { CardSection } from '../../components/CardSection/CardSection';
import { Resources } from '../../components/Resources/Resources';
import { Layout } from '../../components/Layout/Layout';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import { BlackFridayCard } from '../../components/BlackFridayCard/BlackFridayCard';

export const HomePage = props => {
  const { user, res } = props;

  return (
    <Layout user={user}>
      <Helmet>
        <title>Devwares - Tools and resources for developers and designers </title>
        <meta name="title" content="Devwares - Tools and resources for developers and designers" />
        <meta
          name="description"
          content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
        />
        <meta
          property="og:title"
          content="Devwares - Tools and resources for developers and designers "
        />

        <meta
          property="og:description"
          content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
        />
        <meta
          property="twitter:title"
          content="Devwares - Tools and resources for developers and designers"
        />
        <meta
          property="twitter:description"
          content="We are creating high quality tools and resources to aid developers and designers during the development of their projects"
        />
        <link rel="canonical" href="https://www.devwares.com/" />
        <meta property="og:url" content="https://www.devwares.com/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
        <meta
          name="twitter:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
      </Helmet>
      <HeroSection />
      {/* <BlackFridayCard /> */}
      <CardSection />
      <Resources res={res} />
      <FeaturedIn />
    </Layout>
  );
};
